var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.content.length)?_c('header',{staticClass:"c-front-page-hero",style:({
		'--content-height': _vm.contentHeight ? (_vm.contentHeight + "px") : null,
	})},[_c('div',{staticClass:"\n\t\t\tflex flex-col\n\t\t\tjustify-center\n\t\t\tcol-start-1 col-span-5\n\t\t\tself-stretch\n\t\t",class:['pt-md pb-sm', '>=1024:pt-sm']},[(_vm.content.length > 1)?_c('nav',{staticClass:"flex items-center gap-x-8 mb-sm",attrs:{"aria-label":"intro-område-navigering"}},_vm._l((_vm.content),function(item,index){return _c('input',{key:index,staticClass:"\n\t\t\t\t\tappearance-none\n\t\t\t\t\tborder border-primary-button\n\t\t\t\t\tw-12\n\t\t\t\t\th-12\n\t\t\t\t\trounded-full\n\t\t\t\t\ttransition-colors\n\t\t\t\t\tduration-300\n\t\t\t\t",class:{
					'bg-primary cursor-pointer': _vm.introIndex !== index,
					'bg-primary-button': _vm.introIndex === index,
				},attrs:{"type":"radio","name":"intro-nav","aria-label":("Gå til intro-område " + (index + 1)),"aria-current":_vm.introIndex === index ? 'true' : null},on:{"change":function($event){$event.target.checked && (_vm.introIndex = index)}}})}),0):_vm._e(),_vm._v(" "),_c('TransitionGroup',{staticClass:"\n\t\t\t\tc-front-page-hero__content-transition\n\t\t\t\trelative\n\t\t\t\tflex flex-col\n\t\t\t\tjustify-center\n\t\t\t\titems-start\n\t\t\t\tmy-auto\n\t\t\t",class:{
				'c-front-page-hero__content-transition--back':
					_vm.introIndex < _vm.previousIntroIndex,
			},attrs:{"tag":"div","name":"t-front-page-hero__content-transition"},on:{"before-leave":_vm.updateContentHeight,"after-enter":function($event){_vm.contentHeight = 0}}},[_vm._l((_vm.content),function(item,index){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.introIndex === index),expression:"introIndex === index"}],key:index,ref:"content",refInFor:true,staticClass:"c-front-page-hero__content"},[_c('BaseH1',{staticClass:"text-primary-button",domProps:{"textContent":_vm._s(item.title)}}),_vm._v(" "),(item.teaser)?_c('p',{staticClass:"\n\t\t\t\t\t\t\twhitespace-pre-wrap\n\t\t\t\t\t\t\tmt-xs\n\t\t\t\t\t\t\ttransform\n\t\t\t\t\t\t\t-translate-y-4\n\t\t\t\t\t\t",domProps:{"textContent":_vm._s(item.teaser)}}):_vm._e(),_vm._v(" "),(item.link && item.link[0])?_c('BaseButton',{staticClass:"inline-flex mt-xs",attrs:{"tag":"NuxtLinkExt","to":item.link[0].url,"target":item.link[0].target},domProps:{"textContent":_vm._s(item.link[0].name)}}):_vm._e()],1)]})],2)],1),_vm._v(" "),_c('Transition',{attrs:{"name":"t-front-page-hero__media","mode":"out-in"}},[(_vm.heroMedia)?_c('div',{key:_vm.introIndex,class:[
				'c-front-page-hero__media',
				'relative bg-primary-medium rounded-l-16',
				'col-start-6 col-span-7 self-stretch' ]},[(
					_vm.heroMedia.fileType === 'Image' &&
					(_vm.heroMedia.cropUrl || _vm.heroMedia.url)
				)?_c('UmbracoImageExt',{class:[
					'c-front-page-hero__image bg-primary-medium relative rounded-l-16 overflow-hidden' ],attrs:{"alt":_vm.heroMedia.altText,"widths":[320, 480, 720, 834, 480 * 2, 720 * 2, 834 * 2],"source-url":_vm.heroMedia.cropUrl || _vm.heroMedia.url,"source-width":_vm.heroMedia.width,"source-height":_vm.heroMedia.height,"aspect-ratio":834 / 407,"image-class-names":"object-cover"}}):(
					_vm.heroMedia.fileType === 'Video' && _vm.heroMedia.cropUrl
				)?_c('div',{staticClass:"\n\t\t\t\t\tc-front-page-hero__video\n\t\t\t\t\trelative\n\t\t\t\t\trounded-l-16\n\t\t\t\t\th-full\n\t\t\t\t"},[_c('video',{ref:"heroVideo",staticClass:"\n\t\t\t\t\t\tabsolute\n\t\t\t\t\t\tleft-0\n\t\t\t\t\t\ttop-0\n\t\t\t\t\t\tw-full\n\t\t\t\t\t\th-full\n\t\t\t\t\t\trounded-l-16\n\t\t\t\t\t\tobject-cover\n\t\t\t\t\t",attrs:{"id":"hero-video","autoplay":"","muted":"","playsInline":"","loop":"","preload":"none","crossOrigin":"anonymous","aria-label":"stemningsvideo"},domProps:{"muted":true},on:{"canplay":_vm.pauseIfItShouldntPlay}},[_c('source',{attrs:{"src":_vm.heroMedia.cropUrl,"type":"video/mp4"}}),_vm._v("\n\t\t\t\t\tYour browser does not support the video tag.\n\t\t\t\t")])]):_vm._e()],1):_c('div',{key:"nothing"})]),_vm._v(" "),_c('Transition',{attrs:{"name":"t-front-page-hero__media","mode":"out-in"}},[(_vm.heroMedia && _vm.heroMedia.fileType === 'Video')?_c('div',{staticClass:"absolute right-0 bottom-0"},[(_vm.videoIsPlaying)?_c('BaseButton',{staticClass:"\n\t\t\t\t\tc-front-page-hero__toggle\n\t\t\t\t\tabsolute\n\t\t\t\t\tbottom-16\n\t\t\t\t\tright-layout-margin\n\t\t\t\t\tz-10\n\t\t\t\t",attrs:{"aria-label":"Sæt videoen på pause","aria-controls":"hero-video"},on:{"click":function($event){return _vm.toggleVideo()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgPauseIcon',{staticClass:"w-24 h-24"})]},proxy:true}],null,false,2914896865)}):_c('BaseButton',{staticClass:"\n\t\t\t\t\tc-front-page-hero__toggle\n\t\t\t\t\tabsolute\n\t\t\t\t\tbottom-16\n\t\t\t\t\tright-layout-margin\n\t\t\t\t\tz-10\n\t\t\t\t",attrs:{"aria-label":"Afspil videoen","aria-controls":"hero-video"},on:{"click":function($event){return _vm.toggleVideo()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"w-24 h-24 flex items-center justify-center"},[_c('SvgPlayIcon',{staticClass:"w-16 ml-2"})],1)]},proxy:true}],null,false,778818076)})],1):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }